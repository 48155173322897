import React from "react"
import ContactPage from "../modules/ContactPage"
import NewsPage from "../modules/NewsPage"
import PortfolioPage from "../modules/PortfolioPage"
import PrivacyPolicyPage from "../modules/PrivacyPolicy"
import SustainabilityPage from "../modules/SustainabilityPage"
import AboutUsPage from "../modules/AboutUsPage"

export const componentDictionary = {
  _ContactPage: ContactPage,
  _NewsPage: NewsPage,
  _PortfolioPage: PortfolioPage,
  _PrivacyPolicyPage: PrivacyPolicyPage,
  _SustainabilityPage: SustainabilityPage,
  _AboutUsPage: AboutUsPage,
}

export const routePrefix = "/page/"
