import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { animated } from "react-spring"
import { ENDPOINTS } from "../api/endpoints"
import Footer from "../components/footer/footer"
import { useFetch } from "../hooks/useFetch"
import useWindowSize from "../hooks/useWindowSize"
import setFooterBackgroundColor from "../services/footer"
import { getComponentByName, strapiComponents } from "../services/getComponent"
import useCustomTransition from "../services/transition"

const PrivacyPolicyPage = () => {
  const pageTransition = useCustomTransition()
  const { i18n } = useTranslation()

  const { response } = useFetch(ENDPOINTS.PRIVACY_POLICY_PAGE, i18n.language)

  const size = useWindowSize()

  useEffect(() => {
    setFooterBackgroundColor(true)
    return () => setFooterBackgroundColor(false)
  }, [])

  return (
    <animated.div
      className="spring_wrapper privacy-policy-page"
      style={pageTransition}
    >
      {response?.data?.attributes?.privacyPolicyPage &&
      response.data.attributes.privacyPolicyPage.length ? (
        <>
          {response.data.attributes.privacyPolicyPage.map((section, i) => {
            const { privacyPolicyPage } = response.data.attributes

            const heroData =
              privacyPolicyPage.length &&
              privacyPolicyPage[0].__component === strapiComponents.HERO
                ? privacyPolicyPage[0]
                : null

            return (
              <div key={i}>
                {getComponentByName(section, size.width, heroData)}
              </div>
            )
          })}
          <Footer></Footer>
        </>
      ) : null}
    </animated.div>
  )
}

export default PrivacyPolicyPage
