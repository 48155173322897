import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { routePrefix } from "../const/routes"

const IndexPage = () => {
  React.useEffect(() => {
    window.location.pathname = routePrefix
  }, [])

  return (
    <Layout>
      <Seo title="Green Lanes" />
    </Layout>
  )
}

export default IndexPage
